




























































import { Component, Vue, Prop } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import { saveAs } from "file-saver";
import ICrudClient from "@/lib/ICrudClient";
import OpenCallFilesColumns from "@/data/columns/OpenCallFiles";
import OpenCallFileForm from "@/components/forms/OpenCallFileForm.vue";

@Component({
  components: {
    ModelTable,
    OpenCallFileForm
  }
})
export default class OpenCallFilesTable extends Vue {
  @Prop()
  public crud!: ICrudClient<any>;

  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  private columns = OpenCallFilesColumns;
  private editDialog = false;
  private selectedid = "";

  private item = {
    openCallId: this.openCallId,
    submissionDate: new Date().toISOString(),
    activeScorers: [],
    comments: ""
  };

  async download(name: string) {
    this.$forceUpdate();
    try {
      this.downloadBlob(await this.$service.downloadFile(name), name);
    } catch (e) {
      console.error(e);
    }
    this.$forceUpdate();
  }
  private downloadBlob(blob: Blob, name: string) {
    saveAs(
      blob,
      name
        .split("-")
        .slice(5)
        .join("-")
    );
  }
}
