export default [
    {
      name: "file/name",
      required: true,
      label: "File Name",
      align: "left",
      sortable: true,
      field: (i: any) => {
        const name = i.file.name
          .split("-")
          .slice(5)
          .join("-");
        if (name.length < 42) {
          return name;
        }
        return (
          name.slice(0, 20) + ".." + name.slice(name.length - 20, name.length)
        );
      }
    },
    {
      name: "date",
      required: true,
      label: "Date",
      align: "left",
      sortable: true,
      field: (i: any) => i.date ? new Date(Date.parse(i.date)) : undefined,
      format: (val: Date) => (val ? val.toLocaleDateString() : "")
    },
    {
        name: "comment",
        required: true,
        label: "Comment",
        align: "left",
        sortable: true,
        field: (i: any) => i.comment
    }
  ];