
































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import FileInput from "@/components/FileInput.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    FileInput,
    DatePicker
  }
})
export default class OpenCallFileForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  private item: any = null;

  async mounted() {
    if (this.id === "new") {
      this.item = {
        openCallId: this.openCallId,
        date: null,
        comment: ""
      };
    }
  }

  private emitSave() {
    this.$emit('save', this.item);
  }
}
